// RPSession creates a new session checking process for checking against OP session states
// parameters
// clientId: the client id of the RP
// sessionState: raw string value of session state from the end user id token
//               OR a function that returns the session state string
// targetOrigin: the origin URI of the OP
// options:
//     interval:       number indicating the number of milliseconds between each session check (default: 5000)
//
//     reauthenticate: function that will reauthenticate the user;
//                     optionally return true false indicating success (default: noop)
//
//     logout:         function that will log the user out (default: noop)
//
//     opFrameId:      string representing the id of the iframe in the parent window that the
//                     will receive session check messages to (default: op_session_iframe)
//
// functions:
//     start(): start polling the OP session management iframe
//     stop():  stop polling the OP session management iframe
import Cookie from 'js-cookie'

export function RPSession(clientId, sessionState, targetOrigin, options = {}) {
    this.clientId = clientId;

    if (typeof sessionState === 'function') {
        this.sessionState = sessionState;
    } else {
        this.sessionState = function () {
            return sessionState
        };
    }

    this.targetOrigin = targetOrigin;
    this.interval = options.interval || (5 * 1000);
    this.logout = options.logout || function () { };
    this.reauthenticate = options.reauthenticate || function () { };
    this.opFrameId = options.opFrameId || 'op_session_iframe';
}

RPSession.prototype.start = function () {
    let self = this;

    // check to refresh parent
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('state') == "refresh") {
        window.parent.location.reload();
        return;
    }

    // check to redirect back to error
    if (urlParams.has('error')) {
        window.parent.location.href = `${this.targetOrigin}/#/product-list/?error=${urlParams.get('error')}`
        return
    }

    let alertMessage = null;
    let previousSessionState = self.sessionState();

    window.addEventListener('message', function (e) {
        console.log("[RP] RECEIVED MESSAGE: ", e.data);
        if (e.origin !== self.targetOrigin) {
            return;
        }

        if (previousSessionState !== self.sessionState()) {
            window.parent.location.reload();
            return;
        }

        let parts = e.data.split("|")
        switch (parts[0]) {
            case '3pc-disabled':
                self.thirdPartyCookieAlert();
                break;
            case 'warningClear':
                if (alertMessage) {
                    alertMessage.remove();
                }
                break;
            case 'changed':
                self.reauthenticate();
                break;
            case 'warning':
                let timeRemaining = parts[1] || '5'
                alertMessage = self.alertMessage(window.parent, 'Warning', 'Your session will expire in ' + timeRemaining + ' minutes due to inactivity. Please click ok to extend your session.');
                break;
            case 'error':
                // always log the user out if an error is returned
                console.log("[RP] logout by error");
                self.stop();
                self.logout();
                break;
        }
    });

    // get the parent window OP iframe
    var win = window.parent.document.getElementById(this.opFrameId);
    if (!win) {
        return;
    }

    win = win.contentWindow;

    var checkSession = function () {
        var ss = self.sessionState();

        console.log("[RP] SENDING MESSAGE: ", self.clientId + ' ' + ss);
        win.postMessage(self.clientId + ' ' + ss, self.targetOrigin);
    };

    // fire the check immediately
    checkSession();

    // start the session check
    this.intervalTimer = setInterval(checkSession, this.interval);
};

/**
 * Render an alert message when third party cookies are disabled.  Show the message once per login or browser session.
 * Set a cookie that we will use as a temporary session.  It will last 2 hours then the user will be logged out.
 */
RPSession.prototype.thirdPartyCookieAlert = function () {
    let cookieMessage = `
                <p>
                We are unable to set a session management cookie because your browser is blocking 3rd party cookies.  
                Please follow these instructions to unblock 3rd party cookies or whitelist *.illuminateed.com domain. 
                <a href="https://support.illuminateed.com/hc/en-us/articles/360037474333-resolution-logged-out-immediately-after-arriving-at-landing-page" target="_blank">
                    Help Doc
                </a>
                <br /><br />
                As a courtesy, we are able to create a 2 hour session for you to complete your current tasks. Please note
                that the App Picker is not available to navigate between products.
                </p>
            `;

    if (typeof Cookie.get('3pc_disabled_msg') === 'undefined') {
        Cookie.set('3pc_disabled_msg', '1');
        this.alertMessage(window.parent, 'Unable To Set Session Cookie', cookieMessage);
    }

    // stop the existing check session timer and replace it with a new one that checks a static cookie
    if (typeof Cookie.get('3pc_session') === 'undefined') {
        this.stop();
        Cookie.set('3pc_session', '1', { expires: 2 / 24 }); // 2 hour cookie

        let self = this;
        this.intervalTimer = setInterval(function () {
            if (typeof Cookie.get('3pc_session') === 'undefined') {
                console.log("[RP] 3pc session cookie is not set anymore so logout:");
                Cookie.remove('3pc_disabled_msg');
                self.logout();
            }
        }, this.interval);
    }
};

/**
 * Render a generic alert message that can be dismissed.
 *
 * @param win
 * @param title
 * @param body
 */
RPSession.prototype.alertMessage = function (win, title, body) {

    let self = this;
    let node = document.createElement("div");
    node.setAttribute("name", "alert")
    let closeFunc = function (e) {
        e.preventDefault();
        node.remove();

        if (this.intervalTimer) {
            self.stop();
        }
        self.reauthenticate();
    };

    let closeIcon = document.createElement("a");
    closeIcon.style.cssText = 'font-size:22px; float:right; color: #C0C3CF;text-decoration:none;';
    closeIcon.innerText = 'X';
    closeIcon.href = '#';
    closeIcon.onclick = closeFunc;

    let titleDiv = document.createElement("div");
    titleDiv.style.cssText = 'font-family: Cerebri Sans; font-style: medium; font-size:20px; margin-bottom: 20px;';
    titleDiv.innerText = title;

    let contentDiv = document.createElement("div");
    contentDiv.style.cssText = 'font-family: Cerebri Sans; font-style: regular; font-size:16px; line-height: 30px';
    contentDiv.innerHTML = body;

    let closeBtn = document.createElement("a");
    closeBtn.style.cssText = `border-radius: 4px 4px; background-color:#0049FF; color:#FFFFFF; 
                        position:absolute; bottom: 15px; right: 15px; padding:10px 30px;
                        font-size:14px; font-family: Cerebri Sans; text-decoration:none;
                        `;
    closeBtn.innerText = 'OK';
    closeBtn.href = '#';
    closeBtn.onclick = closeFunc;

    node.id = "rp-alert-message";
    node.style.cssText = `border-radius: 4px 4px; border:1px solid #CCCCCC; 
                   background-color: white; position: absolute; top:200px; z-index:1000; 
                   width:600px; margin-left:-300px; left: 50%; min-height:250px; padding:15px 15px 60px 15px`;
    node.append(closeIcon);
    node.append(titleDiv);
    node.append(contentDiv);
    node.append(closeBtn);

    win.document.body.append(node);
    return node;
};


RPSession.prototype.stop = function () {
    clearInterval(this.intervalTimer);
};


// // Example implementation
// var rpSession = new RPSession(
//     'example-app-client-id',
//     function(){ /*... get cookie with session state value */},
//     'https://op-provider-origin.com', {
//         interval: 5 * 1000, // recheck the session state every 5 seconds
//         reauthenticate: function() {
//             // perform an action to silently reauthenticate the user
//             // this should send an authorization request to the OP with:
//             //     prompt=none
//             //     id_token_hint=<current end user id token>
//             // upon successful authentication update the session state
//             //
//             // optionally return true | false if authentication was successful or not
//             // if this function returns false the logout function will be called
//             // default: noop
//         },
//         logout: function() {
//             // perform an action to logout the user
//             // this will be triggered if the reauthenticate function returns false
//             // eg. redirect to logout endpoint
//             // default: noop
//         },
//     }
// );

// // start checking session
// rpSession.start();

// // stop checking session
// rpSession.stop();
